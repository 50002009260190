// Execute immediately since script is at end of body
const tabs = document.querySelectorAll('.service-tabs__tab');
const tabContents = document.querySelectorAll('.service-contents__content');
const mainContainer = document.querySelector('.service-container');

function updateTab(target) {
  const targetContent = document.getElementById(target);
  if (targetContent && targetContent.classList.contains('active')) {
    return;
  }

  tabs.forEach(t => t.classList.remove('active'));
  tabContents.forEach(content => content.classList.remove('active'));

  const activeTab = document.querySelector(`.service-tabs__tab[data-tab="${target}"]`);
  if (activeTab) {
    activeTab.classList.add('active');
    targetContent.classList.add('active');
    
    // Update URL without waiting
    location.hash = `tab:resources:${target}`;
    
    if (mainContainer.classList.contains('loading')) {
        mainContainer.classList.remove('loading');
    }
  }
}

// Initialize tabs immediately
const hash = window.location.hash.substring(1);
if (hash) {
  const tab = hash.replace('tab:resources:', '');
  updateTab(tab);
} else {
  updateTab('incorporation');
}

// Event listeners
tabs.forEach(tab => {
  tab.addEventListener('click', (e) => {
    e.preventDefault();
    const target = tab.getAttribute('data-tab');
    updateTab(target);
  });
});